import Page from "components/Page"
import CompositeMinter from "components/CompositeMinter"

const CompositeMinterPage = () => {
  //const { contractAddress, projectId } = useParams()
  return (
    <Page>
      {
        <CompositeMinter />
      }
    </Page>
  )
}

export default CompositeMinterPage