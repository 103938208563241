import {
  Box, Breadcrumbs, Grid, Link, SvgIcon, Typography,
} from "@mui/material"
import DotSelector from "./DotSelector"
import { Image } from "@mui/icons-material"

const CompositeMinter = () => {

    const dots = Array(5).fill({}).map((_, i) => { return { tokenId: i.toString(), imageUrl: `/media/sample-dots/${i}.png` } })

   return (
    <Box>
      <Breadcrumbs aria-label="breadcrumb" sx={{marginBottom: 4}}>
        <Link href="/projects" underline="hover" sx={{color: "#666"}}>
          Home
        </Link>
        <Typography>
          Dots Composite Builder
        </Typography>
      </Breadcrumbs>
      <Grid spacing={2} container>
       <Grid item md={5} xs={12} sm={12}>
        <DotSelector dots={dots} />
       </Grid>
       <Grid item md={7} xs={12} sm={12}>
        <Image component={SvgIcon} sx={{width: "100%", paddingLeft: [0, 0, 2]}} />
       </Grid>
      </Grid>
    </Box>
  )
}

export default CompositeMinter