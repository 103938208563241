import { 
    Box, 
    Grid, 
    Typography 
  } from "@mui/material"
  import Page from "components/Page"
  
  const AboutPage = () => {
    return (
      <Page>
        <Grid container spacing={3}>
        <Grid item md={6}>
        <Box marginTop={10}>
          <Typography variant="h1" fontSize={64}>Fusing Art+Tech</Typography>
          <p>Working from the Boston suburb of Sudbury, C.J. Bordeleau (b. 1988, American) better known in the digital art world as r4v3n (pronounced “raven”), is a generative artist whose work is a compelling exploration of the interplay between technology and human emotion. His art, as seen in collections like “Moments”, “Pieces of Me”, “Color Streams” and “Marfa: Middle of Somewhere", invites the viewer into an immersive journey on the bridging of the analog and digital worlds.</p>
          <p>Influenced by abstract expressionism and color field painting, r4v3n skillfully blends the precision of algorithms with the expressive nature of human feelings. His art aims to go beyond the visual, aiming to provoke thought and evoke emotion, offering a sense of hope that technology will elevate our humanity, not replace it. </p>
          <p>Each creation by r4v3n is a delicate exploration of life’s moments, translated into a digital language that captures both the visible and the hidden meanings. He focuses on the interplay between the simple and the intricate, striving to create art that resonates with a diverse audience.
  Approaching his art with humility and a continual desire to learn, r4v3n views his journey in the field of digital art as one of constant evolution. His goal is to produce works that captivate visually while fostering a deeper connection with the audience, utilizing his digital canvas to reflect the vibrancy and depth of human creativity.</p>
        </Box>
        </Grid>
        <Grid item md={6}>
        <Box marginTop={10}>
          <img alt='r4v3n' src="/media/r4v3n.jpg" width="100%" />
          <p style={{marginTop: '-4px', textAlign: 'right'}}><i>photo by Rainer Hosch</i></p>
        </Box>
        </Grid>
        </Grid>
      </Page>
    )
  }
  
  export default AboutPage