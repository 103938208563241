import React, { useState } from 'react';
import './DotSelector.css';

interface Dot {
    tokenId: string;
    imageUrl: string;
}

interface DotSelectorProps {
  dots: Dot[];
}

const DotSelector: React.FC<DotSelectorProps> = ({ dots }) => {
  const [selectedDots, setSelectedDots] = useState<Dot[]>([]);

  const toggleSelectDot = (dot: Dot): void => {
    if (selectedDots.includes(dot)) {
      setSelectedDots(selectedDots.filter(item => item !== dot));
    } else {
      setSelectedDots([...selectedDots, dot]);
    }
  };

  return (
    <div className="dot-selector-grid">
      {dots.map((dot: Dot, index: number) => (
        <div
          key={index}
          className={`dot-selector-item ${selectedDots.includes(dot) ? 'selected' : ''}`}
          style={{ backgroundImage: `url(${dot.imageUrl})` }}
          onClick={() => toggleSelectDot(dot)}
        />
      ))}
    </div>
  );
};

export default DotSelector;
