export const mainnetContractConfig = [
  {
    "CONTRACT_VERSION": "V3",
    "CORE_CONTRACT_ADDRESS": "0xd9b7eC74C06c558A59AfdE6a16E614950730F44d",
    "MINT_CONTRACT_ADDRESS": "",
    "MEDIA_URL": "https://r4v3n-art-blocks-engine-mainnet.s3.amazonaws.com",
    "TOKEN_URL": "https://token.artblocks.io",
    "GENERATOR_URL": "https://generator.artblocks.io",
    "EDIT_PROJECT_URL": 'https://creator.artblocks.io',
  },
  {
    "CONTRACT_VERSION": "V2",
    "CORE_CONTRACT_ADDRESS": "0x0a1bbd57033f57e7b6743621b79fcb9eb2ce3676",
    "MINT_CONTRACT_ADDRESS": "",
    "MEDIA_URL": "https://media-proxy.artblocks.io/0x0a1bbd57033f57e7b6743621b79fcb9eb2ce3676",
    "TOKEN_URL": "https://token.artblocks.io",
    "GENERATOR_URL": "https://generator.artblocks.io",
    "EDIT_PROJECT_URL": 'https://creator.artblocks.io',
  },
  {
    "CONTRACT_VERSION": "V2",
    "CORE_CONTRACT_ADDRESS": "0xa319C382a702682129fcbF55d514E61a16f97f9c",
    "MINT_CONTRACT_ADDRESS": "",
    "MEDIA_URL": "https://media-proxy.artblocks.io/0xa319C382a702682129fcbF55d514E61a16f97f9c",
    "TOKEN_URL": "https://token.artblocks.io",
    "GENERATOR_URL": "https://generator.artblocks.io",
    "EDIT_PROJECT_URL": 'https://creator.artblocks.io',
  },
  {
    "CONTRACT_VERSION": "V2",
    "CORE_CONTRACT_ADDRESS": "0xa7d8d9ef8d8ce8992df33d8b8cf4aebabd5bd270",
    "MINT_CONTRACT_ADDRESS": "",
    "MEDIA_URL": "https://media-proxy.artblocks.io/0xa7d8d9ef8d8ce8992df33d8b8cf4aebabd5bd270",
    "TOKEN_URL": "https://token.artblocks.io",
    "GENERATOR_URL": "https://generator.artblocks.io",
    "EDIT_PROJECT_URL": 'https://creator.artblocks.io',
  }
]

export const testnetContractConfig = [
  {
    "CONTRACT_VERSION": "V3",
    "CORE_CONTRACT_ADDRESS": "0x639ae7D29E70F32827826b8d6FF031a93c2C02b7",
    "MINT_CONTRACT_ADDRESS": "0x7330539523F532a57142B8E63E90B2bE215E6C84",
    "MEDIA_URL": "https://media-proxy-staging.artblocks.io/0x639ae7d29e70f32827826b8d6ff031a93c2c02b7",
    "TOKEN_URL": "https://token.staging.artblocks.io",
    "GENERATOR_URL": "https://generator-staging-goerli.artblocks.io",
    "EDIT_PROJECT_URL": 'https://creator.artblocks.io',
  },
]

export const arbTestnetContractConfig = [
  {
    "CONTRACT_VERSION": "V3",
    "CORE_CONTRACT_ADDRESS": "0x62e37f664b5945629b6549a87f8e10ed0b6d923b",
    "MINT_CONTRACT_ADDRESS": "0x23ff83f3891127025b465c526f8d381659887575",
    "MEDIA_URL": "https://media-proxy-staging.artblocks.io/0x639ae7d29e70f32827826b8d6ff031a93c2c02b7",
    "TOKEN_URL": "https://token.staging.artblocks.io",
    "GENERATOR_URL": "https://generator-staging-goerli.artblocks.io",
    "EDIT_PROJECT_URL": 'https://creator.artblocks.io',
  },
]