import {
  Box,
  Typography,
  Link
} from "@mui/material"
import { Project } from "utils/types"
import { parseAspectRatio } from "utils/scriptJSON"
import ProjectDate from "components/ProjectDate"
import TokenView from "components/TokenView"

interface Props {
  project: Project
  width?: number
  showDescription?: boolean
}

const ProjectPreview = ({project, width=280, showDescription=false}: Props) => {
  if (!project) {
    return null
  }

  const token = project?.tokens[0]
  return (
    <Link href={`/project/${project.contract.id}/${project.projectId}`} underline="hover">
    <Box>
      <Box>
          <Typography variant="h1" fontSize={28}>
            {project.name}
          </Typography>
        <Typography variant="h6" mb={2} fontSize={18}>
          {new Date(Number(project.createdAt) * 1000).getFullYear()}
        </Typography>
      </Box>
      <TokenView
        contractAddress={project.contract.id}
        tokenId={token?.tokenId}
        width={width}
        invocation={token?.invocation}
        aspectRatio={project.aspectRatio || parseAspectRatio(project.scriptJSON)}
      />
      <Box>
        <Box sx={{display: "flex", alignItems:"center"}}>

          <ProjectDate
            startTime={project.minterConfiguration?.startTime}
          />
        </Box>
      </Box>
    </Box>
        </Link>
  )
}

export default ProjectPreview
